<template>
  
    <v-container class="mb-5">

        <v-row>
            <v-col >

                <v-container>
                    <v-row 
                        v-if="textMessage">
                        <v-col>
                            <v-alert 
                            :text="textMessage" 
                            :type="Object.keys(errorState).length > 0 ? 'error' : 'success'"
                            icon="mdi-alert-circle-outline" />
                        </v-col>
                    </v-row>
					<!--v-row>
						<v-col>
							<v-btn 
								@click="updateSessions" 
								prepend-icon="mdi-refresh" > 
								Refresh 
							</v-btn>
						</v-col>
					</v-row-->
                    <v-row>
						<v-col 
							cols="12">
							<v-btn
								@click="this.deleteSession()"
								prepend-icon="mdi-trash-can"
								rounded="1"
								color="danger"
								variant="outlined"
								block>
								Scollega tutte le sessioni
							</v-btn>
						</v-col>
                        <v-col>

							<v-list>
								<v-list-item
									v-for = "(item, index) in searchItems" 
									:key="index"
									:title = "item.regionName + ', ' + item.country"
									:subtitle = "item.platform + ' - ' + item.date"
									:active="thisSession === parseInt(item.session) ? true : false"
									class="text-left m-2 rounded border" >
									<template v-slot:prepend>
										<v-avatar>
											<v-icon v-if="item.device_type === 'Desktop'">mdi-desktop-classic</v-icon>
											<v-icon v-else-if="item.device_type === 'Mobile Device'">mdi-cellphone-link</v-icon>
											<v-icon v-else-if="item.device_type === 'Mobile Phone'">mdi-cellphone</v-icon>
											<v-icon v-else-if="item.device_type === 'Tablet'">mdi-tablet</v-icon>
											<v-icon v-else>mdi-alert-outline</v-icon>
										</v-avatar>
									</template>
									<template v-slot:append>
										<v-btn
											@click="deleteSession(item)"
											icon="mdi-trash-can"
											variant="text" />
									</template>
								</v-list-item>
							</v-list>
                            
                        </v-col>
                    </v-row>
                </v-container>

            </v-col>
        </v-row>
        
    </v-container>

</template>

<script>

	/* eslint-disable */
	import { db } from "../dexie";

    export default {

        data() {
            return {
                textMessage: false,
				items: [],
				searchItems: [],
				thisSession: this.$store.getters.getField("session")
            }
        },

        methods: {

			async updateSessions() {

				let result = await this.$store.getters.getLocalTable("exkart_sessions");
				if(result !== false) {
					this.items = result;
					this.searchItems = this.items;
				}

				if(!await this.$store.dispatch("isToUpdateData"))
					return false;

				if(!await this.$store.dispatch("isConnected"))
					return false;

				this.$store.dispatch("log", "update sessions from database server");
				
				let data = {};
				data.switch = "getSessions";
				data.user = this.$store.state.user;
				data.auth_key = this.$store.state.auth_key;
				result = await this.$store.dispatch("post", data);
				if(result === false) {
					this.textMessage = "internal error server, pls try later";
					return false;
				} else if(result.result === false) {
					this.textMessage = result.text;
					return result.result;
				}

				// update sessions on dexie db
				result.table = "exkart_sessions";
				await this.$store.commit("insertLocalDatabase", result);
				this.items = result.details || result.values;
				this.searchItems = this.items;

			},

			async deleteSession(sessions = false) {

				if(! await this.$store.dispatch("isConnected")) {
					this.$store.dispatch("log", "non sei connesso ad una rete internet");
					return false;
				}

				let stringa = "vuoi davvero cancellare questa sessione?";
				if(sessions === false) {
					stringa = "sei sicuro di voler cancellare tutte le sessioni?";
					sessions = this.items.filter(e => e.session != this.thisSession);
				} else
					sessions = [ sessions ];
				if(!confirm(stringa))
					return false;

				let foreachResult = true;
				let dati = {};
				dati.switch = "deleteSession";
				dati.auth_key = await this.$store.getters.getField('auth_key');
				sessions.forEach( async session => {

					this.$store.dispatch("log", " elimino sessione nr " + session.session );
					dati.session = session.session;
					dati.user = session.user;
					let result = await this.$store.dispatch("post", dati);
					if(!result || !result.result) {
						this.$store.dispatch("log", result.text || "internal error, pls try later");
						foreachResult = false;
						return false;
					} else 
						this.$store.dispatch("log", result.text);

					db.exkart_sessions
						.where({session: session.session, user: session.user})
						.delete()
						.then(() => this.$store.dispatch("log", "sessione "+session.session+" eliminata correttamente"));
						
				});
				
				this.updateSessions();
				return foreachResult;

			},
            
        },

		async mounted() {

			this.items = await this.$store.getters.getLocalTable("exkart_sessions");
			this.searchItems = this.items;
			if(await this.$store.dispatch("isToUpdateData"))
				this.updateSessions();
			
		}

    }

</script>