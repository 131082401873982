<template>

  <v-form
    ref="loginForm"
    v-model="form"
    @submit.prevent="onSubmit"
    max-wi >

    <v-container>
      <v-row>
        <div
          class="col-lg-6 col-md-8 col-sm-10 col-12 offset-lg-3 offset-md-2 offset-sm-1 offset-0" >

          <v-container>

            <v-row>

              <v-col>
                <img
                  v-if="this.$vuetify.theme.current.dark === true"
                  src='../assets/white_nobg.png'
                  alt="Vue logo"
                  class="w-50 img-fluid" />
                <img
                  v-if="this.$vuetify.theme.current.dark === false"
                  src='../assets/black_nobg.png'
                  alt="Vue logo"
                  class="w-50 img-fluid" />
              </v-col>

            </v-row>

            <v-row>
              <v-col>

                <v-tabs 
                  v-model="type"
                  fixed-tabs >
                  <v-tab value="login">login</v-tab>
                  <v-tab value="register">register</v-tab>
                </v-tabs>

              </v-col>
            </v-row>

            <v-row 
              v-if="textMessage">
              <v-col>
                <v-alert 
                  :text="textMessage" 
                  type="error"
                  icon="mdi-alert-circle-outline" />
              </v-col>
            </v-row>

            <v-row
              v-if="type === 'login'">

              <v-col class="col-12">
                <a :href=linkGoogleAuth>
                  <img src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png" alt="Login con Google"/>
                </a>
              </v-col>

              <v-col class="col-12 text-center">
                <div class="divider-container">
                  <hr class="divider-line" />
                    <span class="divider-text">or</span>
                  <hr class="divider-line" />
                </div>
              </v-col>

              <v-col class="pt-3">
                <v-text-field
                  :rules="[rules.required, rules.username]"
                  :error="errorstate.username"
                  :variant="variantfield"
                  :readonly="loading"
                  v-model="elements.username"
                  type="text"
                  hide-details="auto"
                  ref="username"
                  label="Username"
                  class="mb-3 pt-1" />
                <v-text-field
                  :rules="[rules.required]"
                  :error="errorstate.password"
                  :variant="variantfield"
                  :type="showchar.password ? 'text' : 'password'"
                  :readonly="loading"
                  @dblclick="showchar.password = !showchar.password"
                  v-model="elements.password"
                  ref="password"
                  label="Password"
                  class="mb-3" />
              </v-col>

            </v-row>

            <v-row
              v-if="type === 'register'">

              <v-col class="col-12">
                <a :href=linkGoogleAuth>
                  <img src="https://developers.google.com/identity/images/btn_google_signin_light_normal_web.png" alt="Login con Google"/>
                </a>
              </v-col>

              <v-col class="col-12 text-center">
                <div class="divider-container">
                  <hr class="divider-line" />
                    <span class="divider-text">or</span>
                  <hr class="divider-line" />
                </div>
              </v-col>
              
              <v-col>

                <v-text-field
                  :rules="[rules.required, rules.username]"
                  :error="errorstate.username"
                  :variant="variantfield"
                  :readonly="loading"
                  v-model="elements.username"
                  counter="50"
                  type="text"
                  ref="username"
                  label="Username"
                  class="mb-3" />

                <v-text-field
                  :error="errorstate.mail"
                  :readonly="loading"
                  :rules="[rules.required, rules.mail]"
                  :variant="variantfield"
                  v-model="elements.email"
                  type="email"
                  counter="200"
                  ref="mail"
                  label="Mail"
                  class="mb-3" />

                <v-text-field
                  :rules="[rules.required]"
                  :error="errorstate.first_name"
                  :variant="variantfield"
                  :readonly="loading"
                  v-model="elements.first_name"
                  type="text"
                  counter="50"
                  ref="first_name"
                  label="First name"
                  class="mb-3" />

                <v-text-field
                  :error="errorstate.last_name"
                  :variant="variantfield"
                  :readonly="loading"
                  v-model="elements.last_name"
                  type="text"
                  counter="50"
                  ref="last_name"
                  label="Last name"
                  class="mb-3" />

              <!--/v-col>
              <v-col col="12"-->

                <v-list
                    lines="one"
                    density="compact"
                    class="m-2 p-0 text-left"
                    style="font-size: 11px; line-height: 1.4em;"
                    slim >
                    <div :class="elements.password && elements.password.length >= 8 ? 'text-success' : 'text-danger'"> - almeno 8 caratteri </div>
                    <div :class="elements.password && /[A-Z]/.test(elements.password) ? 'text-success' : 'text-danger'"> - almeno 1 carattere maiuscolo </div>
                    <div :class="elements.password && /[a-z]/.test(elements.password) ? 'text-success' : 'text-danger'"> - almeno 1 carattere minuscolo </div>
                    <div :class="elements.password && /[0-9]/.test(elements.password) ? 'text-success' : 'text-danger'"> - almeno 1 numero </div>
                    <div :class="elements.password && /[\?\*\+\-\!\,\.\_]/.test(elements.password) ? 'text-success' : 'text-danger'"> - almeno 1 simbolo </div>
                </v-list>

                <v-text-field
                  :rules="[rules.required, rules.password]"
                  :error="errorstate.password"
                  :variant="variantfield"
                  :type="showchar.password ? 'text' : 'password'"
                  :readonly="loading"
                  :hint="valuehints.password"
                  @dblclick="showchar.password = !showchar.password"
                  v-model="elements.password"
                  ref="password"
                  counter="20"
                  label="Password"
                  class="mb-3" />

              <!--/v-col>
              <v-col col="12"-->

                <v-text-field
                  :error="errorstate.repeatpassword"
                  :variant="variantfield"
                  :type="showchar.repeatpassword ? 'text' : 'password'"
                  :readonly="loading"
                  :rules="[rules.required, rules.password]"
                  :hint="valuehints.repeatpassword"
                  @dblclick="showchar.repeatpassword = !showchar.repeatpassword"
                  v-model="elements.repeatpassword"
                  ref="repeatpassword"
                  counter="20"
                  label="Repeat password"
                  class="mb-3" />

              </v-col>

            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  :loading="loading"
                  :text="type === 'login' ? type : 'signup'"
                  variant="outlined"
                  size="large"
                  type="submit"
                  block />
              </v-col>
            </v-row>
            </v-container>

          </div>
      </v-row>
    </v-container>

  </v-form>

</template>
  
<script>
  
  export default {

    data: () => {
      return {
        linkGoogleAuth: "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=426345678886-g589p7sre9maiun2b718sqeqk56bmo5c.apps.googleusercontent.com&redirect_uri=https://techysumo.it/test.php&scope=email profile",
        form: false,
        textMessage: false,
        type:'login',
        loading: false,
        logo: false,
        variantfield: 'underlined',
        elements: {},
        showchar: {},
        errorstate: {},
        valuehints: {},
        rules: {
          required: value => !!value || 'field is required',
          mail: value => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/.test(value) || 'mail not valid',
          password: value => /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[?.\-!*+])[A-Za-z\d?.\-!*+]{8,}/.test(value) || 'password is not valid',
          username: value => value.length < 50 && value.length > 5 || 'username is not valid'
        }
      }
    },

    name: 'loginView',
    methods: {

      async onSubmit () {

        if(!this.form) {
          this.textMessage = await this.getString(910671);
          return false;
        }

        this.loading = true;
        this.errorstate = {};
        let string = null;

        const data = {
          switch: this.type == "login" ? "loginUser" : "addUser",
          ...this.elements,
        };

        let result = await this.$store.dispatch("post", data);
        this.loading = false;
        if(result === false) {
          string = await this.getTranslation(706139);
          this.$emit('sendNotify', string);
          this.textMessage = string;
          this.loading = false;
          this.$store.dispatch("log", string);
          return false;
        } else if(result.result === false) {
          string = result.text;
          this.$emit('sendNotify', string);
          this.textMessage = string;
          this.loading = false;
          this.$store.dispatch("log", string);
          return false;
        } else if(result.result === true) {
          this.$store.commit("saveField", {field: "login", value: true});
          this.$store.commit("saveField", {field: "session", value: result.details.session});
          this.$store.commit("saveField", ["auth_key", result.details.auth_key]);
          this.$store.commit("saveField", ["user", result.details.user]);
          this.$store.commit("updateAppData");
          this.$router.push('/');
          string = await this.getTranslation(596293);
          this.$store.dispatch("log", string);
          return true;
        }

      },

    },

    async beforeCreate() {

      if(this.$router.currentRoute.value.path === '/register')
        this.type = 'register';

    },

    updated() {

      this.textMessage = false;
      // this.$refs.username.focus();

    },

    mounted() {
      this.$refs.username.focus();
      this.$store.dispatch("log", "pagina login montata correttamente");
    },

  }

</script>

<style scoped>

  .divider-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divider-line {
    flex-grow: 1;
    border: 0;
    border-top: 1px solid #ccc; /* Puoi personalizzare il colore della linea */
    margin: 0 10px; /* Distanza tra il testo e le linee */
  }

  .divider-text {
    padding: 0 10px; /* Spazio intorno al testo */
    font-weight: bold; /* Facoltativo per evidenziare il testo */
  }

</style>