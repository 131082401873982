<template>
  
    <v-container class="mb-5">

        <v-row>
            <v-col >

                <v-container>
                    <!--v-row>
                        <v-col>
                            Hi {{ this.$store.state.username }},<br/>
                            Your data profile:
                        </v-col>
                    </v-row-->
                    <!-- v-row>
                        <v-btn
                            @click="getDataProfile()"
                            variant="outlined"
                            class="mx-auto"
                            append-icon="mdi-refresh"
                            text="refresh data"
                            color="primary" />
                    </v-row -->
                    <v-row 
                        v-if="textMessage">
                        <v-col>
                            <v-alert 
                                :text="textMessage" 
                                :type="Object.keys(errorState).length > 0 ? 'error' : 'success'"
                                icon="mdi-alert-circle-outline" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>

                            <v-form 
                                @submit.prevent="onSubmit"
                                v-model="form" >
                            
                                <v-text-field
                                    :rules="[rules.username, rules.required]"
                                    :error="errorState.username"
                                    :disabled="!editFields"
                                    @change="editElements.push('username')"
                                    v-model="elements.username"
                                    label="username"
                                    counter="50" />
                                
                                <v-text-field 
                                    :rules="[rules.first_name, rules.required]"
                                    :error="errorState.first_name"
                                    :disabled="!editFields"
                                    @change="editElements.push('first_name')"
                                    v-model="elements.first_name"
                                    label="Nome" />
                                
                                <v-text-field 
                                    :rules="[rules.last_name, rules.required]"
                                    :error="errorState.last_name"
                                    :disabled="!editFields"
                                    @change="editElements.push('last_name')"
                                    v-model="elements.last_name"
                                    label="Cognome" />
                                
                                <v-text-field 
                                    :rules="[rules.email, rules.required]"
                                    :error="errorState.email"
                                    :disabled="!editFields"
                                    @change="editElements.push('email')"
                                    v-model="elements.email"
                                    label="Mail" />

                                <v-list 
                                    v-if="editFields"
                                    lines="one"
                                    density="compact"
                                    class="m-2 p-0 text-left"
                                    style="font-size: 11px; line-height: 1.4em;"
                                    slim >
                                    <div :class="!editElements.includes('password') || elements.password.length >= 8 ? 'text-success' : 'text-danger'"> - almeno 8 caratteri </div>
                                    <div :class="!editElements.includes('password') || /[A-Z]/.test(elements.password) ? 'text-success' : 'text-danger'"> - almeno 1 carattere maiuscolo </div>
                                    <div :class="!editElements.includes('password') || /[a-z]/.test(elements.password) ? 'text-success' : 'text-danger'"> - almeno 1 carattere minuscolo </div>
                                    <div :class="!editElements.includes('password') || /[0-9]/.test(elements.password) ? 'text-success' : 'text-danger'"> - almeno 1 numero </div>
                                    <div :class="!editElements.includes('password') || /[\?\*\+\-\!\,\.\_]/.test(elements.password) ? 'text-success' : 'text-danger'"> - almeno 1 simbolo </div>
                                </v-list>
                                
                                <v-text-field 
                                    :rules="[rules.password, rules.required]"
                                    :error="errorState.password"
                                    :disabled="!editFields"
                                    :type="hiddenvalue ? 'password' : 'text'"
                                    :append-inner-icon="hiddenvalue ? 'mdi-eye-off' : 'mdi-eye'"
                                    @change="editElements.push('password')"
                                    @click:append-inner="hiddenvalue = !hiddenvalue"
                                    @dblclick="hiddenvalue = !hiddenvalue"
                                    v-model="elements.password"
                                    label="Password" />
                                
                                <v-text-field 
                                    v-if="editFields"
                                    :rules="[rules.password, rules.required]"
                                    :error="errorState.repeat_password"
                                    :disabled="!editFields"
                                    :type="hiddenvalue ? 'password' : 'text'"
                                    :append-inner-icon="hiddenvalue ? 'mdi-eye-off' : 'mdi-eye'"
                                    @change="editElements.push('repeat_password')"
                                    @click:append-inner="hiddenvalue = !hiddenvalue"
                                    @dblclick="hiddenvalue = !hiddenvalue"
                                    v-model="elements.repeat_password"
                                    label="Conferma password" />

                            </v-form>
                            
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn
                                :color="editFields ? 'success' : 'primary'"
                                :text="editFields ? 'save' : 'edit'"
                                :ripple="false"
                                :append-icon="editFields ? 'mdi-content-save' : 'mdi-pencil'"
                                @click="onSubmit()"
                                variant="flat"
                                size="large"
                                block />
                        </v-col>
                        <v-col 
                            v-if="editFields" >
                            <v-btn
                                :ripple="false"
                                @click="resetForm()"
                                append-icon="mdi-close-box"
                                text="cancel"
                                variant="flat"
                                color="primary"
                                size="large"
                                block />
                        </v-col>
                    </v-row>
                </v-container>

            </v-col>
        </v-row>
        
    </v-container>

</template>

<script>
    /* eslint-disable */
    export default {

        data() {
            return {
                form: false,
                editFields: false,
                elements: {},
                hiddenvalue: true,
                editElements: [],
                errorState: {},
                textMessage: false,
                backup: {},
                rules: {
                    required: value => !!value || 'field is required',
                    username: value => !!value 
                        && value.length >= 5 
                        && value.length <= 50 
                        || 'username is invalid',
                    first_name: value => !!value 
                        && value.length >= 1 
                        && value.length <= 50 
                        || 'nome is invalid',
                    last_name: value => !!value 
                        && value.length >= 1 
                        && value.length <= 50 
                        || 'cognome is invalid',
                    email: value => !!value 
                        && /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/.test(value) 
                        || 'email is invalid',
                    password: value => !!value 
                        && (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value) || !this.editElements.includes('password'))
                        || "password is invalid"
                }
            }
        },

        methods: {

            async onSubmit() {

                this.errorState = {};
                this.textMessage = false;
                
                if(this.editFields === false) {
                    this.editFields = true;
                    this.backup = { ...this.elements };
                    return false;
                } else if(this.editElements.length < 1) {
                    this.editFields = !this.editFields;
                    this.textMessage = "nessuna modifica necessaria";
                    this.$store.dispatch("log", "nessuna modifica necessaria");
                    return false;
                }

                if(!this.form)
                    return false;

                if(this.editElements.includes('email') && this.rules.email(this.elements.email)) {
                    
                    this.errorState.email = true;
                    this.textMessage = "mail not valid";
                    return false;
                    
                }
                
                if(this.editElements.includes('repeat_password') && this.editElements.includes('password') === false) {

                    this.errorState.password = true;
                    this.errorState.repeat_password = true;
                    this.textMessage = "passwords not equals";
                    return false;

                }
                
                if(this.editElements.includes('password') && this.elements.password !== this.elements.repeat_password) {

                    this.errorState.password = true;
                    this.errorState.repeat_password = true;
                    this.textMessage = "passwords not equals";
                    return false;

                }

                let data = {};
                data.switch = "updateFields";
                data.table = "users";
                data.user = this.$store.state.user;
                data.session = this.$store.state.session;
                data.auth_key = this.$store.state.auth_key;
                data.fields = [];
                this.editElements
                    .filter( e => e !== 'repeat_password')
                    .forEach( e => data.fields.push({field: e, value: this.elements[e]}) );
                data.find = [{ field: "user", value: data.user }];
                let result = await this.$store.dispatch("post", data);

                if(result === false) {
                    this.$store.dispatch("log", "internal error server, pls try later");
                    this.textMessage = "internal error server, pls try later";
                } if(result.result === false) {

                    this.textMessage = result.text;
                    return false;
                    
                } else if(result.result === true) {
                    this.textMessage = "data saved";
                    this.editFields = false;
                    this.editElements = [];
                    this.errorState = {};
                    this.hiddenvalue = true;
                    this.elements.password = this.backup.password;
                    this.elements.repeat_password = this.backup.repeat_password;
                    this.backup = {};
                }

            },

            resetForm() {

                this.textMessage = false;
                this.elements = { ...this.backup };
                this.editFields = false;
                this.errorState = {};
                this.hiddenvalue = true;
                this.editElements = [];

            },

            async getDataProfile() {

                let data = {};
                data.table = "exkart_genericTable";
                data.where = { table: "userProfile" };
                let dataProfile = await this.$store.getters.getLocalTable(data);
                dataProfile.forEach( e => this.elements[e.field] = e.value );
                this.elements.password = "*************";
                this.elements.repeat_password = "*************";

                if(! await this.$store.dispatch("isToUpdateData"))
                    return false;

                if(! await this.$store.dispatch("isConnected")) {
                    this.textMessage = "non connesso ad internet";
                    this.$store.dispatch("log", this.textMessage);
                    return false;
                }

                this.$store.dispatch("log", "update profile data da db su server");

                data = {};
                data.switch = "getMe";
                data.user = this.$store.state.user;
                let result = await this.$store.dispatch("post", data);

                if(result === false) {
                    this.textMessage = "internal error server, pls try later";
                    return false;
                } else if(result.result === false) {
                    this.textMessage = result.text;
                    return result.result;
                } else {
                    Object
                        .keys(result.details)
                        .forEach( e => this.$store.commit("insertLocalDatabase", {table: 'exkart_genericTable', values: {table: 'userProfile', field: e, value: result.details[e] }}) );
                    this.elements = result.details;
                    this.elements.password = "*************";
                    this.elements.repeat_password = "*************";
                    return true;
                }
                
            }
            
        },

        async beforeMount()  {

            this.getDataProfile();
            
        }

    }

</script>