if(!('indexedDB' in window))
	console.log("This browser doesn't support IndexedDB.");

const DATABASE_VERSION = 7;
const DATABASE_NAME = "exkart_database";

import Dexie from 'dexie';

export const db = new Dexie(DATABASE_NAME);
db.version(DATABASE_VERSION).stores({
  exkart_sessions: '&session, user, date',
  exkart_genericTable: '[table+field], value',
  exkart_logs: '++id, date, string',
  exkart_strings: '[string+language], description'
});