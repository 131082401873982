<template>

	<div class="container-fluid">

		<div class='row'>
			<div class='col'>
				<div id="barcodescanner"></div>
			</div>
		</div>

	</div>

</template>

<script>

	import Quagga from 'quagga';

	export default {

		data() {
			return {
				
			}
		},

		name: 'barcodeScanner',

		methods: {
			
		},

		mounted() {

			Quagga.init({
				inputStream: {
					area: { // defines rectangle of the detection/localization area
						top: "0%",    // top offset
						right: "0%",  // right offset
						left: "0%",   // left offset
						bottom: "0%"  // bottom offset
					},
					singleChannel: false, // true: only the red color-channel is read
					name: 'Live',
					type: 'LiveStream',
					target: document.querySelector('#barcodescanner'), // Elemento DOM dove mostrare il video
				},
				decoder: {
					readers: ['code_128_reader', 'ean_reader', 'ean_8_reader', 'code_39_reader', 'codabar_reader'], // Aggiungi i formati che vuoi scansionare
				},
				locator: {
					patchSize: "medium", // può essere 'x-small', 'small', 'medium', 'large', 'x-large'
					halfSample: true
				},
				locate: true, // Attiva la localizzazione dei codici a barre
			}, (err) => {
				if (err) {
					console.error(err);
					return;
				}
				this.$store.dispatch("log", 'QuaggaJS avviato con successo');
				Quagga.start();
			});

			// Aggiungi l'evento per disegnare i rettangoli
			Quagga.onProcessed((result) => {
				const drawingCanvas = Quagga.canvas.dom.overlay; // Ottieni il canvas
				const context = Quagga.canvas.ctx.overlay; // Ottieni il contesto del canvas

				// Pulisci il canvas prima di disegnare
				context.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);

				if (result) {
					if (result.boxes) {
						result.boxes.forEach((box) => {
							// Disegna il rettangolo
							Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, context, { color: 'green', lineWidth: 2 });
						});
					}

					if (result.box) {
						// Evidenzia il codice a barre localizzato (rettangolo principale)
						Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, context, { color: 'blue', lineWidth: 2 });
					}

					if (result.codeResult && result.codeResult.code) {
						// Mostra il codice decodificato
						this.$store.dispatch("log", `Codice rilevato: ${result.codeResult.code}`);
					}
				}
			});

			// Ascolta i risultati della scansione
			Quagga.onDetected((data) => {
				this.$store.dispatch("log", 'Codice scansionato:', data.codeResult);
				/*Quagga.stop();
				Quagga.offDetected();*/
			});

		},

		beforeUnmount() {
			// Ferma e rimuovi il listener di QuaggaJS quando il componente viene smontato
			Quagga.stop();
			Quagga.offDetected();
		},

	}

</script>